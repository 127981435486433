import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { AngularFirestore } from '@angular/fire/firestore';

@Component({
    selector: 'my-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
 
    helpOpen:boolean = false;
    loading:boolean = false;

    constructor(private router: Router, private afs: AngularFirestore
 ) {
    
        afs.firestore.settings({ timestampsInSnapshots: true });
        afs.firestore.enablePersistence();
 }

    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
    }
    // billerHelp()
    // {
    //     // this.router.navigate();
    // }
    // billwalletHelp()
    // {
        
    // }

    Help(para)
    {
        if(para == 'biller')
        {
            window.open("https://biller.counttrees.com","_blank");
        }
        if(para == 'billwallet')
        {
            window.open("https://billwallet.counttrees.com","_blank");
        }
        
    }

}
