import { Component,OnInit,OnDestroy} from '@angular/core';
import { ProductOperationsService } from '../productOperations.service';


@Component({
    selector: 'no-connection',
    templateUrl: './noConnection.component.html',
    styleUrls: ['./noConnection.component.css'],
  })
  export class NoConnectionComponent implements OnInit {
    
  
    constructor(private service:ProductOperationsService) { }
     ngOnInit(){

     }
  }