<clr-main-container>
  <clr-header class="header-6">
    <div class="branding">
      <a href="#" class="nav-link">
        <span class="clr-icon clr-clarity-logo"></span>
      </a>
    </div>
    <div class="header-nav" [clr-nav-level]="1">
      <a class="nav-link" href="#" [routerLink]="['/home']" routerLinkActive="active">
        <span class="nav-text">HOME</span>
      </a>
      <a class="nav-link" href="#" [routerLink]="['/about']" routerLinkActive="active">
        <span class="nav-text">ABOUT</span>
      </a>
      <a class="nav-link" href="#" [routerLink]="['/careers']" routerLinkActive="active">
        <span class="nav-text">CAREERS</span>
      </a>
      <!--<a class="nav-link disabled" href="#" [routerLink]="['/howto']" routerLinkActive="active">
        <span class="nav-text">VIDEOS</span>
      </a>-->
    </div>

    <div class="header-actions">
      <!-- <a class="nav-link" href="#" [routerLink]="['/support']" routerLinkActive="active"> -->
          <!-- <span class="nav-text">Help</span> -->
     
      <clr-dropdown style="z-index:1200;">
        <button class="nav-text unique" clrDropdownTrigger>
            PRODUCTS
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
            <a href="https://play.google.com/store/apps/details?id=com.counttrees.biller&hl=en_US" target="_blank" clrDropdownItem>Biller</a>
           <!-- <a href="https://billwallet.counttrees.com" target="_blank" clrDropdownItem>Billwallet</a>>-->
            <a href="https://play.google.com/store/apps/details?id=com.counttrees.billwallet&hl=en_US" target="_blank" clrDropdownItem>Pupape</a>
        </clr-dropdown-menu>

      </clr-dropdown>
      <clr-dropdown style="z-index:1200;">
          <button class="nav-text unique" clrDropdownTrigger>
              HELP
          </button>
          <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
              <a href="https://biller.counttrees.com/" target="_blank" clrDropdownItem>Biller</a>
              <!--<a href="https://billwallet.counttrees.com/docs/how_to_use_billwallet/" target="_blank" clrDropdownItem>Billwallet</a>-->
              <a href="https://pupape.counttrees.com/" target="_blank" clrDropdownItem>Pupape</a>
          </clr-dropdown-menu>
  
        </clr-dropdown>
      <!-- <a class="nav-link" (click)="helpOpen=true;" style="cursor: pointer;">
        <span class="nav-text unique">HELP</span>
      </a> -->
      <!-- <a class="nav-link" href="#" [routerLink]="['/blog']" routerLinkActive="active">
        <span class="nav-text">Blog</span>
      </a> -->
    </div>

  </clr-header>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</clr-main-container>
 
<div class="modal static bump-down" [hidden]="!helpOpen">
  <div class="modal-dialog modal-sm" role="dialog" aria-hidden="true">
      <div class="modal-content">
          <div style="height:100%;width:100%;z-index:1100;position:absolute;" *ngIf="loading">
              <span class="spinner spinner-inverse">
                  Loading...
              </span>

          </div>
           <div class="modal-header text-center">
              
             <span class="modal-title" style="font-size:16px;">Choose Product</span>
              <button aria-label="Close" class="close" type="button" (click)="helpOpen = false;">
                    <clr-icon aria-hidden="true" shape="close"></clr-icon>
                </button>
          </div>
          <div class="modal-body">
             <div class="row">
               <div class="col-sm-12 text-center">
                 <div class="btn-group btn-link">
                   <button class="btn" (click)="Help('billwallet')" style="height:120px;width:110px;"> <img src="../assets/images/billwallet.png" style="height:70%;width:100%;"><div>Billwallet</div></button>
                   <button class="btn" (click)="Help('biller')"     style="height:120px;width:110px;"> <img src="../assets/images/biller.png" style="height:70%;width:100%;"><div>Biller</div></button>
                 </div>
               </div>
             </div>
             <!-- <button class="btn btn-primary" (click)="helpOpen = false;" style="float:right;">Close</button> -->
          </div>
         
      </div>
  </div>
</div>
<div *ngIf="helpOpen" class="modal-backdrop fade in" aria-hidden="true"></div>