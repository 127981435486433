<div class="content-container">
    <div class="content-area">

        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 clr-col-xs-12 text-center">

            <p style="text-align:center">
                <img src="./assets/images/webbg.gif">
            </p>
            <p><span class="badge">New Milestone: 10000+ Happy Customers</span></p>
            <h3 class="landing-heading">
                <b>Digital Growth, Simplified!</b>
            </h3>
            <h6 class="landing-subheading text-muted">#RealTime Bill Exchange Platform &emsp; #Inventory Management
                &emsp; <br> #Instant Online Store &emsp; #Marketing</h6>
        </div>
        <br>

        <div class="clr-row">
            <div class="clr-col-lg-6 clr-col-12">
                <div class="card">
                    <div class="card-block special">
                        <div class="card-media-block">
                            <img src="./assets/images/billerlogo.png" class="card-media-image">
                            <div class="card-media-description">
                                <span class="card-media-title">
                                    <b>BILLER</b>
                                    <br>
                                    Realtime Billing application
                                </span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="card-block">
                        <p class="card-text" style="font-size:small; text-align:justify">
                            Biller is a POS & digital billing solution which an organization can use to generate
                            , send invoice & estimates in realtime using your mobile.Customer receives it immediately as 
                            SMS with basic information & a link for the bill in pdf.This app promotes completely paperless billing.
                        </p>
                    </div>
                    <div class="card-footer container">
                        <div class="clr-row">
                            <div class="clr-col">
                                <button class="btn btn-primary btn-sm own-primary" (click)=getbillerurl()>Get App</button>
                            </div>
                            <div class="clr-col">
                                <button class="btn btn-primary btn-sm own-primary" (click)=getBillerApk()>
                                    <clr-icon shape="download"></clr-icon> Get APK</button>
                            </div>
                            <div class="clr-col footer-right">
                                <button class="btn btn-primary btn-sm own-primary" (click)="openBiller()">Know More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-6 clr-col-12">
                <div class="card">
                    <div class="card-block special">
                        <div class="card-media-block">
                            <img src="./assets/images/pupapelogo.png" class="card-media-image">
                            <div class="card-media-description">
                                <span class="card-media-title">
                                    <b>PUPAPE</b><br>
                                    Online Shopping Platform & Digital Bill Organizer
                                </span>
                            </div>
                        </div>

                    </div>
                    <div class="card-block">
                        <p class="card-text" style="font-size:small; text-align:justify">
                            Pupape is an application to be used by users to organize bills, shop from a nearby store, and redeem the offer from local stores. It has three aspects to keep your all shopping needs and data safe organized for future use.
                             The three pillars of the pupape are billwallet, nearby stores, and offer.
                        </p>
                        <h6></h6>
                    </div>
                    <div class="card-footer container">
                        <div class="clr-row">
                            <div class="clr-col col-sm-6 col-xs-6">
                                <button class="btn btn-primary btn-sm own-primary" (click)=getpupapeurl()>Get App</button>
                            </div>
                            <div class="clr-col col-sm-6 col-xs-6">
                                <button class="btn btn-primary btn-sm own-primary" (click)=getPupapeApk()><clr-icon shape="download"></clr-icon>Get APK</button>
                            </div>
                            <div class="clr-col col-sm-6 col-xs-6 footer-right">
                                <button class="btn btn-primary btn-sm own-primary" (click)="openpupape()">Know More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            
             <div class="clr-row">
                <div class="clr-col-lg-6 clr-col-12">
                <div class="card">
                    <div class="card-block special">
                        <div class="card-media-block">
                            <clr-icon shape="cog" class="card-media-image" style="color:#00796b;"></clr-icon>
                            <div class="card-media-description">

                                <span class="card-media-text">
                                    <b>BILLWALLET API</b>
                                    <br>
                                    Bill receiver & organizer.
                                </span>

                            </div>
                        </div>
                    </div>
                    <div class="card-block">
                        <p class="card-text" style="font-size:small; text-align:justify">
                            Billwallet API is a platform which can be integrated with any kind of POS solution to send
                            digital bills to customer in a flash.
                            Here customer convenience is the outcome but at little expense of your money as printing
                            bills will cost almost double the amount.
                        </p>
                        <h5> </h5>
                    </div>
                    <div class="card-footer container">
                        <div class="row">
                            <div class="col-sm-6 col-xs-6">
                            </div>
                            <div class="col-sm-6 col-xs-6 footer-right">
                                <button class="btn btn-primary btn-sm own-primary" (click)="openBillwallet()">Know
                                    More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>

        <br>
        <br>
        <div class="clr-row">
            <div class="clr-col-lg-2 clr-col-md-2 clr-col-sm-12 clr-col-xs-12"></div>
            <div class="clr-col-lg-4 clr-col-md-4 clr-col-sm-12 clr-col-xs-12" style="float:right; text-align:right">
                <h3 style="text-align:right">Our Philosophy</h3>
                <br>
                <span>We say "No" to printing bills, go green. We believe in Digital Transactions.
                    We believe in DIGITAL INDIA.</span>
            </div>
            <div class="clr-col-lg-4 clr-col-md-4 clr-col-sm-12 clr-col-xs-12 text-center">
                <img src="./assets/images/Philosophy.png" style="width:100px; height:100px; margin-top:5%">
            </div>
            <div class="clr-col-lg-2 clr-col-md-2 clr-col-sm-12 clr-col-xs-12"></div>
        </div>
        <br>
        <br>
        <div class="clr-row">
            <div class="clr-col-lg-2 clr-col-md-2 clr-col-sm-12 clr-col-xs-12"></div>
            <div class="clr-col-lg-4 clr-col-md-4 clr-col-sm-12 clr-col-xs-12 text-center">
                <img src="./assets/images/community.png" style="width:100px; height:100px; margin-top:15%">
            </div>
            <div class="clr-col-lg-4 clr-col-md-4 clr-col-sm-12 clr-col-xs-12" style="float:left; text-align:justify">
                <h3 style="text-align:left">Community</h3>
                <br>
                <span>We are proactively working on all your issues.
                    We are on all Popular Social media channels. Feel free to reach us anytime.</span>
            </div>
            <div class="clr-col-lg-2 clr-col-md-2 clr-col-sm-12 clr-col-xs-12"></div>
        </div>
        <br>
        <br>
        <div class="clr-row">
            <div class="clr-col-lg-2 clr-col-md-2 clr-col-sm-12 clr-col-xs-12"></div>
            <div class="clr-col-lg-4 clr-col-md-4 clr-col-sm-12 clr-col-xs-12" style="float:right;text-align:justify">
                <h3 style="text-align:right">Contribution</h3>
                <br>
                <span>We go paperless digital bill and make sustainable world. In addition we plant & encourage people to plant trees so as
                    to maintain the greenery.</span>
            </div>
            <div class="clr-col-lg-4 clr-col-md-4 clr-col-sm-12 clr-col-xs-12 text-center">
                <img src="./assets/images/contribution.png" style="width:100px; height:150px">
            </div>
            <div class="clr-col-lg-2 clr-col-md-2 clr-col-sm-12 clr-col-xs-12"></div>
        </div>
    </div>
</div>