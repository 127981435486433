import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss'],
})
export class CareersComponent implements OnInit {
  dashboardActive: any;
  managementActive: any;
  cloudActive: any;

  constructor() { }

  ngOnInit() {
  }

}
