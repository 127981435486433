import { Component} from '@angular/core';

@Component({
 
    selector:'app-tutorial',
    templateUrl: './tutorial.component.html'
})

export class tutorialComponent{
    
}