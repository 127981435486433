
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProductOperationsComponent} from './productOperations.component';

import { EditProductComponent} from './editProduct/editProduct.component';
import { NoConnectionComponent} from './noConnection/noConnection.component';


export const ROUTES45: Routes = [
    
    { path: 'productOperations', redirectTo: 'productOperations', pathMatch: 'full' },
    {
        path:'productOperations',component:ProductOperationsComponent,children:[
            {path:'',redirectTo:'editProduct',pathMatch:'full'},
            {path:'editProduct',component:EditProductComponent},
            {path:'noConnection',component:NoConnectionComponent}
        ]
    }
   
];

export const ROUTING: ModuleWithProviders = RouterModule.forChild(ROUTES45);
