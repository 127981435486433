<div class="content-container">
    <div class="content-area">
            <!-- <br>
        -->
        <div class="clr-row" style="padding-top:2.5rem;padding-bottom:5%;padding-left:10%;padding-right:10%;height:400px;">
            <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-6 clr-col-xs-12 text-center">
                    <!-- width="460" height="250" -->
                <iframe  src="https://www.youtube.com/embed/0rlkA1SZFkg" frameborder="0" 
                    allow="autoplay; encrypted-media" 
                    style="height:100%;width:90%;" allowfullscreen>
                </iframe>
            </div>  
            <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-6 clr-col-xs-12 text-center">
                   <iframe src="https://www.youtube.com/embed/h3q9f3-aR50" frameborder="0" 
                   allow="autoplay; encrypted-media" 
                   style="height:100%;width:90%;" allowfullscreen></iframe>
            </div>
        </div>
        <div class="clr-row" style="padding-top:2.5rem;padding-bottom:5%;padding-left:10%;padding-right:10%;height:400px;">
            <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-6 clr-col-xs-12 text-center">
                <iframe  src="https://www.youtube.com/embed/UkQ2CKvtnyY" frameborder="0" 
                allow="autoplay; encrypted-media" 
                style="height:100%;width:90%;" allowfullscreen></iframe>
            </div>
            
            <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-6 clr-col-xs-12 text-center">  
                <iframe src="https://www.youtube.com/embed/SKKXCUgKjmY" frameborder="0" 
                allow="autoplay; encrypted-media" 
                style="height:100%;width:90%;" allowfullscreen></iframe> 
            </div> 
        </div>
    </div>
</div>