<br>
<br>
<div class="content-container">
    <div class="content-area">
        <div class="clr-row">
            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 clr-col-xs-12 text-center">
                <p style="text-align:center">
                    <img src=".././assets/images/About.png">
                </p>
                <h3>
                    #THE VISION
                </h3>
                <!-- <div align="justify" class="text-capitalize "> -->
                <p style="text-align:justify">
                    The dream started on the Dussehra of 2016. Dream of a cleaner & greener India.
                    Trees are uncountable now. The weather is predictable. The air is clean.
                    The Digital Revolution was the game-changer.
                    Paper bills are obselete. Because, digital billing is easy & hassle-free.
                </p>
                <h3>
                    #THE INITIATIVE
                </h3>

                <p class="text-center"> Keeping in mind our vision we sprang out a complete billing ecosystem. Biller,
                    Billwallet & Pupape.</p>
                <p style="text-align:justify">
                    <b>Biller</b> is to create bill, create invoice, estimate, receipt app for any business. Biller is a digital invoice maker software, supports thermal printer to print invoices. Biller also supports the external barcode for easy billing. 
                    Biller is a cloud POS with realtime sync.Biller makes your shop online with no commission.
                     Customer can see your shop online and buy form Pupape.
                    <br><b>Pupape</b> is an application to be used by users to organize bills, shop from a nearby store, and redeem the offer from local stores.
                    It has three aspects to keep your all shopping needs and data safe organized for future use.
                    The three pillars of the pupape are billwallet, nearby stores, and offer.
                    <br>
                    <p class="text-center"><b><span class="badge">No Computer</span><span class="badge">No
                                Printer.</span><span class="badge"> No Paper.</span><span class="badge"> No
                                Ink.</span><span class="badge">Zero Cost.</span><span class="badge">Only
                                Growth.</span></b>
                        <br>
                        So, you save money & your business grows.

                    </p>
                    <h3> #THE DIFFERENCE</h3>

                    <p style="text-align:justify">We ask for the least information possible & provide blazing fast
                        service.
                        We proactively listen to your problems & work even when don't expect us to.
                        So, feel free to write to us at <b>support@counttrees.com</b>.
                        We also provide robust and performance intensive API platform for digital billing.
                        <br>As a service to the community, We plant trees & encourage others to do the same, which has
                        started a movement.

                    </p>
                    <p style="text-align:center">
                        Be a part of the movement. <br><b>Choose Paperless Billing, Choose Counttrees!</b>
                    </p>
                    <!-- </div> -->
            </div>
        </div>
        <hr>
        <h2 class="text-center">@here</h2>

        <div class="clr-row">
            <div class="clr-col-lg-4 clr-col-md-4 clr-col-sm-12 clr-col-xs-12 text-center">
                <div class="card" style="width:100%">
                    <section class="text-center" style="margin-top:33%;">
                        Counttrees Technologies Private Limited. #26, Floor-2 Sankalpa Pearl City, Varthur, Bengaluru, <br>
                        Karnataka 560087 India
                    </section>
                </div>
            </div>
            <div class="clr-col-lg-8 clr-col-md-8 clr-col-sm-12 clr-col-xs-12 text-center">
                <div class="card" style="width:100%">
                    <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d972.1441217275261!2d77.74778562918102!3d12.934919399430004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae0d35d1248dfb%3A0x4061ae9c3b508373!2sCounttrees%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1608111014093!5m2!1sen!2sin" 
                    width="600" height="300" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
            </div>
        </div>
        <hr>

        <h3 class="text-grey text-center text-uppercase">#The Team</h3>
        <div class="clr-row" style="margin-top:15px">
            <div class="clr-col-lg-4 clr-col-md-4 clr-col-sm-4 col-xs-12" *ngFor="let person of persons;">
                <div class="card">
                    <div class="card-img text-center">
                        <img src="{{person.images}}" alt="" class="img-circle" />
                    </div>
                    <div class="card-block">
                        <p class="card-text">
                            <b class="text-uppercase">{{person.names}}</b>
                            <br>
                            <b>Developement Lead</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- <h3 class="text-grey text-center">#DIRECTORS</h3> -->
        <div class="clr-row" style="margin-top:15px">
            <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-6 clr-col-xs-12" *ngFor="let director of directors">
                <div class="card">
                    <div class="card-img">
                        <!-- (mouseover)="imagechange()"-->
                        <img [src]="director.images" class="img-circle" [alt]="director.names"
                            *ngIf="director && director.images">
                        <!--(mouseout)="revertimage()"/-->
                        <clr-icon shape="user" *ngIf="!(director && director.images)" style="height:142px;width:142px;">
                        </clr-icon>
                    </div>
                    <div class="card-block">
                        <p class="card-text text-center">
                            <b class="text-uppercase">{{director.names}}</b>
                            <br>
                            <b>{{director.roles}}</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <h3 class="text-grey text-center">#MENTORS</h3> -->
        <div class="clr-row" style="margin-top:15px">
            <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-6 clr-col-xs-12" *ngFor="let mentor of mentors">
                <div class="card">
                    <div class="card-img">
                        <img [src]="mentor.images" class="img-circle" [alt]="mentor.names"
                            *ngIf="mentor && mentor.images">
                        <clr-icon shape="user" *ngIf="!(mentor && mentor.images)" style="height:142px;width:142px;">
                        </clr-icon>
                    </div>
                    <div class="card-block">
                        <p class="card-text text-center">
                            <b class="text-uppercase">{{mentor.names}}</b>
                            <br>
                            <b> {{mentor.role}} </b>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="clr-row" style="margin-top:15px">
            <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-6 clr-col-xs-12" *ngFor="let investor of investors">
                <div class="card">
                    <div class="card-img">
                        <img [src]="investor.images" class="img-circle" [alt]="investor.names"
                            *ngIf="investor && investor.images">
                        <clr-icon shape="user" *ngIf="!(investor && investor.images)" style="height:142px;width:142px;">
                        </clr-icon>
                    </div>
                    <div class="card-block">
                        <p class="card-text text-center">
                            <b class="text-uppercase">{{investor.names}}</b>
                            <br>
                            <b>{{investor.roles}}</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <h5 class="text-center">#INTERNS</h5>
        <div class="clr-row text-center" style="margin-top:15px;font-size: 12px;">
            <div class="clr-col-lg-4 clr-col-md-4 clr-col-sm-6 clr-col-xs-12 text-center"
                *ngFor="let two of interns">
                <b class="text-uppercase">{{two.names}}</b>
            </div>
        </div>
        <h5 class="text-center">#CONTRIBUTORS</h5>
        <div class="clr-row text-center" style="margin-top:15px;font-size: 12px;">
            <div class="clr-col-lg-4 clr-col-md-4 clr-col-sm-6 clr-col-xs-12 text-center"
                *ngFor="let one of contributors">
                <b class="text-uppercase">{{one.names}}</b>
            </div>
        </div>
    </div>
</div>