
import { Component, OnInit } from '@angular/core';

@Component({

    styleUrls: ['./home.component.css'],
    templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
    ngOnInit() {

    }
    getbillerurl() {
        window.open("https://play.google.com/store/apps/details?id=com.counttrees.biller&hl=en", "_blank");
    }

    getbillwalleturl() {
        window.open("https://play.google.com/store/apps/details?id=com.counttrees.billwallet&hl=en", "_blank");
    }
    openBiller() {
        window.open("https://biller.counttrees.com", "_blank");
    }
    openBillwallet() {
        window.open("https://pupape.counttrees.com/docs/getting/", "_blank");
    }
    openpupape() {
        window.open("https://pupape.counttrees.com/", "_blank");
    }
    getpupapeurl() {
        window.open("https://play.google.com/store/apps/details?id=com.counttrees.billwallet&hl=en", "_blank");
    }
    getBillerApk() {
        window.open("https://bit.ly/3h2ByeG", "_blank");
    }
    getPupapeApk() {
        window.open("https://bit.ly/3gXFEo6", "_blank")
    }

}
