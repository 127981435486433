<div class="content-container">
    <div class="content-area">
        <br>
        <br>
        <br>
        <br>
        <!-- <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2"></div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" >
                    <div class="card">
                        <div class="card-header">
                            Developers
                        </div>
                        <div class="card-block text-justify">
                            <div class="card-text">
                                <ul>
                                    <li>Self Sufficient Android Developers</li>
                                    <li>Serverless with Angular2+</li>
                                    <li>Google Cloud, Firestore, Cloud Functions</li>
                                    <li>APIs, Documentation, Critical Thinking</li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button class="btn btn-sm btn-link">Footer Action 1</button>
                            <button class="btn btn-sm btn-link">Footer Action 2</button>
                        </div>
                    </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div class="card">
                        <div class="card-header">
                            Mentors
                        </div>
                        <div class="card-block text-justify">
                            <div class="card-text">
                                <ul>
                                    <li>Domain Specialists</li>
                                    <li>Strategic Partners</li>
                                    <li>Planning Partners</li>
                                    <li>Architects</li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button class="btn btn-sm btn-link">Footer Action 1</button>
                            <button class="btn btn-sm btn-link">Footer Action 2</button>
                        </div>
                    </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2"></div>
        </div> -->
        <div class="clr-row" id="customheight">
            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 clr-col-xs-12 text-center">
                <br>
                <div class="text-center">
                    <img src="./assets/images/JOIN.png" style="height:60px">
                </div>
                <h4>we are a small team with a big vision</h4>
                <h6 style="color:#ff5f00">You are enthusiastic, intuitive. <br>You are welcome!</h6>
                <br>
                <br>
                <br>

                <clr-tabs>
                    <clr-tab style="margin-top:10%">
                        <button clrTabLink>Technical</button>
                        <ng-template [(clrIfActive)]="dashboardActive">
                            <clr-tab-content>
                                <ul>
                                    <br>
                                    <li>Self Sufficient Android Developers</li>
                                    <li>Serverless with Angular2+</li>
                                    <li>Google Cloud, Firestore, Cloud Functions</li>
                                    <li>APIs, Documentation, Critical Thinking</li>
                                </ul>
                            </clr-tab-content>
                        </ng-template>
                    </clr-tab>
                    <!-- 
                    <clr-tab>
                        <button clrTabLink>Management</button>
                        <ng-template [(clrIfActive)]="managementActive">
                            <clr-tab-content>
                                <ul>
                                    <li>Best practices in recruitment and staffing in our organization.</li>
                                    <li>Quality relationship with the our clents and recruitment agencies.</li>
                                    <li>Aptly capable technical managers to work with & lead the engineers.</li>
                                    <li>Best finanacial and legal experts.</li>
                                </ul>
                            </clr-tab-content>
                        </ng-template>
                    </clr-tab> -->

                    <clr-tab>
                        <button clrTabLink>Mentors</button>
                        <ng-template [(clrIfActive)]="cloudActive">
                            <clr-tab-content>
                                <ul>
                                    <br>
                                    <li>Domain Specialists</li>
                                    <li>Strategic Partners</li>
                                    <li>Planning Partners</li>
                                    <li>Architects</li>
                                </ul>
                            </clr-tab-content>
                        </ng-template>
                    </clr-tab>
                </clr-tabs>
            </div>
        </div>
        <div class="clr-row">
            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 clr-col-xs-12">
                <address>
                    Share Your resume
                    <clr-icon shape="envelope" class="is-solid"></clr-icon>
                    <a href="mailto:hr.ctpl@gmail.com">hr@counttrees.com</a>
                </address>
            </div>
        </div>
    </div>
</div>