<!-- <div class="card-header">
    <span *ngIf="toEdit">
        Google search results
    </span>
</div> -->

<!-- <div class="card-block "> -->
    <div class="row">
        <div class="col-sm-6 col-xs-12 col-md-6 col-lg-6">
        </div>
        <div class="col-sm-6 col-xs-12 col-md-6 col-lg-6">
            <div class="google-media-search" [innerHTML]="gcsesearch"></div>
        </div>
    </div>
    
<!-- </div> -->