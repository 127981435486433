<br>
<br>
<div class="content-container">
  <div class="content-area">
    <div id='ppHeader'>Privacy Policy</div>
    <div id='ppBody'>
      <div class='ppConsistencies'>
        <div class='col-2'>
          <div class="quick-links text-center">Information Collection</div>
        </div>
        <div class='col-2'>
          <div class="quick-links text-center">Information Usage</div>
        </div>
        <div class='col-2'>
          <div class="quick-links text-center">Information Protection</div>
        </div>
        <div class='col-2'>
          <div class="quick-links text-center">Cookie Usage</div>
        </div>
        <div class='col-2'>
          <div class="quick-links text-center">3rd Party Disclosure</div>
        </div>
        <div class='col-2'>
          <div class="quick-links text-center">3rd Party Links</div>
        </div>
        <div class='col-2'></div>
      </div>
      <div style='clear:both;height:10px;'></div>
      <div class='ppConsistencies'>
        <div class='col-2'>
          <div class="col-12 quick-links2 gen-text-center">Google AdSense</div>
        </div>
        <div class='col-2'>
          <div class="col-12 quick-links2 gen-text-center"> Fair Information Practices
            <div class="col-8 gen-text-left gen-xs-text-center" style="font-size:12px;position:relative;left:20px;">Fair information
              <br> Practices</div>
          </div>
        </div>
        <div class='col-2'>
          <div class="col-12 quick-links2 gen-text-center coppa-pad"> COPPA </div>
        </div>
        <div class='col-2'>
          <div class="col-12 quick-links2 quick4 gen-text-center caloppa-pad"> CalOPPA </div>
        </div>
        <div class='col-2'>
          <div class="quick-links2 gen-text-center">Our Contact Information
            <br>
          </div>
        </div>
      </div>
      <div style='clear:both;height:10px;'></div>
      <div class='innerText'>This policy best describes how we handle and process your information on the application "<strong>Biller</strong>"
        <br>
      </div>
      <span id='infoCo'></span>
      <br>
      <div class='grayText'>
        <strong>Collection of Information</strong>
      </div>
      <br />
      <div class='innerText'>When you interact with us through our App or Services, we may collect or receive the following types of information:
        <ul>
          <li>Information you provide directly to us:
            <ul>
              <li>Contact Information, such as name, phone number and email address while you sign up</li>
              <li>Payment Information, if you choose to upgrade your account's subscription, 
                we may process your payment information as long as your subscription is valid.</li>
              <li>Other Information, such as your store information, product details and bills you generate are 
                processed and stored in our database securely. We will not share any information that you enter 
                in your diary/stories with any third parties.</li>
            </ul>
          </li>
          <li>We may collect some information automatically when you use our application/service, 
            such as the date and time of your visit, your device information such as operating system version.</li>
        </ul>
      </div>
      <br>
      <div class='grayText'>
        <strong>How we use your Information</strong>
      </div>
      <br />
      <div class='innerText'>Your email address is stored in our database as your identity in our application/service.</div>
      <div class='innerText'>No information such as your store information or your sales information will be used. 
        All entries are securely stored while you access the data through your credentials in the application.</div>
        <div class='innerText'>If you choose to upgrade your subscription, your payment information shall be kept for validating your subscription.</div>
      <br>
      <div class='grayText'>
        <strong>How we protect your Information</strong>
      </div>
      <br />
      <div class='innerText'>We take serious measures to help protect your data from loss, theft, 
        unauthorized access, disclosure, alteration, and destruction.</div>
      <div class='innerText'>You may request the deletion of your account whenever you want to stop using the service.</div>
      <div class='innerText'>However, no data storage system or transmission of data over the Internet or any other 
        public network can be guaranteed to be 100 percent secure.</div>
      <br>
      <div class='innerText'>We suggest you use "Biller" application from a verified and trusted source only, 
        such as Google Play Store, any modified or altered version that you may find may have serious security issues and, 
        such usage may corrupt your data and we shall not be responsible for such events.</div>
      <br>
      <div class='innerText'>We highly value your privacy, and we do not share any data that 
        we collect to any third party. All your data is solely owned and controlled by you.</div>
      <br>
      <div class='grayText'>
        <strong>Permissions</strong>
      </div>
      <br />
      <div class='innerText'>
        <ul>
          <li>Camera - To take pictures of products while adding inventory</li>
          <li>Contacts - To Send Bills to your contacts</li>
          <li>Location - To Choose your Store Address while enabling your online store</li>
          <li>Storage - To download xlsx, pdf reports etc</li>
        </ul>
      </div>
      <br>
      <div class='grayText'>
        <strong>Permitted Users:</strong>
      </div>
      <br />
      <div class='innerText'>By using this application you agree that you are at least 16 years old, 
        and if you're not sure, request help from your parents or guardians before you start creating an account.</div>
      <br>
      <div class='grayText'>
        <strong>Changes to this Policy</strong>
      </div>
      <br />
      <div class='innerText'>We may update or modify this Privacy Policy at any time without prior notice. 
        When we update the Privacy Policy, we will revise the "Effective Date" date above and post the new Privacy Policy. 
        We recommend that you review the Privacy Policy each time you visit the service to stay informed of our privacy practices. 
        Any changes will be effective when we post the revised policy. Your continued use of our Services after any changes 
        or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.</div>
    
    <br>
    <div class='blueText'>
      <strong>Contacting Us</strong>
    </div>
    <br />
    <div class='innerText'>If there are any questions regarding this privacy policy, you may contact us using the information below.
      <br>
      <br>
    </div>
    <a href="https://counttrees.com/home">www.counttrees.com</a>
    <div class='innerText'>#26, Floor-2, Sankalpa pearl City, Varthur</div>Bengaluru, Karnataka 560087
    <div class='innerText'>India</div>
    <div>
      <clr-icon shape="envelope" class="is-solid"></clr-icon>
      <a href='mailto:hr.admin@counttrees.com'> admin@counttrees.com
      </a>
    </div>
    <div class='innerText'><clr-icon shape="mobile"></clr-icon> <a href="tel:+918861797773"> +91 88617 97773</a></div>
    <div class='innerText'>
      <br>Last Edited on 13th December, 2020</div>
  </div>
</div>