
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogComponent } from './blog/blog.component';
import { SupportComponent } from './support/support.component';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { CareersComponent } from './careers/careers.component';
import { tutorialComponent } from './tutorial/tutorial.component';
import { PrivacyPolicyComponent } from 'app/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from 'app/terms-conditions/terms-conditions.component';



export const ROUTES: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'about', component: AboutComponent },
    { path: 'careers', component: CareersComponent },
    { path: 'terms', component: TermsConditionsComponent},
    { path: 'privacypolicy', component: PrivacyPolicyComponent },
    { path: 'support', component: SupportComponent },
    { path: 'blog', component: BlogComponent },
    {path:'howto',component:tutorialComponent},
    {path:'productOperations',loadChildren:() => import('./ProductOperations/productOperations.module').then(m => m.productOperationsModule)}
];

export const ROUTING: ModuleWithProviders = RouterModule.forRoot(ROUTES);
