import { Component,OnInit,OnDestroy} from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';


import {DomSanitizer, SafeHtml} from '@angular/platform-browser';


@Component({
    selector: 'google-search',
    templateUrl: './googleSearch.component.html',
    styleUrls: ['./googleSearch.component.css'],
  })
  export class GoogleSearchComponent implements OnInit {
    
    toEdit:boolean = false;
    barcode2Search:string;
    mainUI:any;
    gcsesearch: SafeHtml;//for custom search api by google

    constructor(private http:HttpClient,private sanitizer: DomSanitizer) { }
    ngOnInit(){

      this.gcsesearch = this.sanitizer.bypassSecurityTrustHtml("<gcse:search></gcse:search>");

      var cx = '016820916711928902111:qw0kgpuhihm';
      var gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(gcse, s);
      
    }
    googleProduct()
    {
       
        
          let headers = this.initializeHttpHeaders(); 
          //let options = new RequestOptions({ headers: headers, }); 
        //    return this.http.get('https://dynamics-monitoring-dev.azurewebsites.net/dynamics-monitoring/dynamicscrm/metrics?category=all&timeRange=4000', options)
        
        /*
        'https://www.googleapis.com/customsearch/v1?' + 
        'key=AIzaSyDHL78AFMgBsDfLDLxrkgE2BpQltpU5dZk' + 
        '&cx=001902629152690290887:ek_fbrmwk0m' +
        '&q=searchTerm' +
        '&searchType=image' +
        '&num=10';
        */
        this.http.get('https://www.googleapis.com/customsearch/v1?' + 
        'key=AIzaSyDHL78AFMgBsDfLDLxrkgE2BpQltpU5dZk' + 
        '&cx=001902629152690290887:ek_fbrmwk0m' +
        '&q=bargarh' +
        '&searchType=image' +
        '&num=10').subscribe((res:any)=>{
            console.log('search results');
            console.log(res);
            this.mainUI = res;
        })
    }
    initializeHttpHeaders(): HttpHeaders 
    { 
      let headers = new HttpHeaders(); 
          headers.set('Access-Control-Allow-Headers', 'Content-Type'); 
          headers.set('Access-Control-Allow-Methods', 'POST,PUT,PATCH,DELETE,GET'); 
          headers.set('Access-Control-Allow-Origin', '*'); 
          headers.set('Content-Type', 'application/json'); 
         
          return headers; 

    } 

  }