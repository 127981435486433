
<div class="content-container">
        <div class="content-area">
            <br>
            <br>
            <div class="row" id="customheight">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    
                    <h3> Product Operations Main Page</h3>
                    <span [class.connected]="connected" [class.disconnected]="!connected">
                          {{connected?'Internet Connected':'Internet Disconnected'}}</span>
                    <!-- <a class="nav-link" href="#" [routerLink]="['addProduct']" routerLinkActive="active">
                        <span class="nav-text">ADD</span>
                    </a> -->
                    <!-- <a class="nav-link" href="#" [routerLink]="['editProduct']" routerLinkActive="active">
                        <span class="nav-text">EDIT</span>
                    </a> -->
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center card">
                    <router-outlet></router-outlet>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="margin-top:1rem;">
                    <google-search></google-search>
                </div>
            </div>
            
        </div>
    </div>