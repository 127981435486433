import { Component, OnInit, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import {ProductOperationsService} from './productOperations.service';

@Component({
    selector: 'product-operations',
    templateUrl: './productOperations.component.html',
    styleUrls: ['./productOperations.component.css'],
  })
  export class ProductOperationsComponent implements OnInit {
    
    connected:boolean;
    showSearchResult:boolean = false;
    constructor(private router: Router,private service:ProductOperationsService) { }
  
    ngOnInit() {
      this.service.searchResult.subscribe(val=>{
          if(val){this.showSearchResult = true;}
          else{this.showSearchResult = false;}
      })
      this.connected = navigator.onLine;
      window.addEventListener('online',()=>{this.connected = true;this.navigator('productOperations/editProduct')});
      window.addEventListener('offline',()=>{
        this.connected = false;
        this.navigator('productOperations/noConnection')
      })
      
      if(this.connected)
      {
        // this.navigator('productOperations/editProduct');
        let access = localStorage.getItem('access');
      
        if(!(access && access == 'approved'))
        {
          let user = prompt('who are you');
          //console.log(user);
          if(user && user == 'Charlemagne of Aachen')
          {
              alert('access approved');
              localStorage.setItem('access','approved');
          }
          else
          {
            alert('try again');
            prompt('access denied');
          }
        }
      }
      else
      {
        this.navigator('productOperations/noConnection');
      }

    }



    navigator(param: string) {
        this.router.navigate([param]);
      }
  
  }