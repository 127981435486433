import { Component,OnInit,OnDestroy} from '@angular/core';

import { ProductOperationsService } from '../productOperations.service';
import { Product} from '../model/product';
import { Variant} from '../model/variant';
import { Upload} from '../model/upload';

import { Router, ActivatedRoute} from '@angular/router';
//import { validateConfig } from '@angular/router/src/config';



@Component({
    selector: 'edit-product',
    templateUrl: './editProduct.component.html',
    styleUrls: ['./editProduct.component.css'],
  })
  export class EditProductComponent implements OnInit {
    
  
    constructor(private service:ProductOperationsService, private router:Router,private route:ActivatedRoute) { }
  
    found:boolean;
    showProduct:boolean = false;
    toUpdate2:boolean = false;
    toSave2:boolean = false;
    toCloseModal:boolean = false;
    selectedFiles: FileList | null;
    Max_Size: number = 307200;
    currentUpload: Upload;
    BackupProductImages: {id:number,link:string}[];
    BackupVariantImages:{varId:number,imgId:number,link:string}[];

    validated:boolean = false;
    detailsOf:any;
    product:Product;
    variants:{val:Variant,id:string,toAdd:boolean}[];
    currentVariety: { val: Variant; id: number,toAdd:boolean };
    barcode2Search:string;
    processing:boolean = false;

    globalPref:any;
    emptyProduct:Product = {
        barCode:[],
        brand:null,
        category:null,
        productName:null,
        searchKey: [],
        varRef:{},
        images:['','','','']
    };

    emptyVariant:Variant = {
        barCode : null,
        packSize: null,
        price:null,
        product_ID:null,
        unit:null,
        images:['','','']
    };

    toEdit:boolean = false;

    productId:string;
    variantId:string[] = [];

    addPhoto:boolean = false;

    productImages:string[] = ['https://hgtvhome.sndimg.com/content/dam/images/hgtv/fullset/2010/8/11/2/TS-78365701_pile-of-pickles_s3x4.jpg.rend.hgtvcom.616.822.suffix/1400948851006.jpeg','https://prods3.imgix.net/images/articles/2018_04/nonfeatured-peanut-butter-pickle-sandwich.jpg?auto=format%2Ccompress&ixjsv=2.2.3&w=670','https://images.food52.com/3r4VpTSTK_so4-e48J0UBQXhSJc=/753x502/0136f334-6bb5-4df0-904d-3b370633f51e--food52_pic.jpg','https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbPl8uTcUujVYJKFATs1PAOm0yFDj_Xoy_-_yUXGkqnYCpCQ7Z'];
    variantImages = [{images:['https://hgtvhome.sndimg.com/content/dam/images/hgtv/fullset/2010/8/11/2/TS-78365701_pile-of-pickles_s3x4.jpg.rend.hgtvcom.616.822.suffix/1400948851006.jpeg','https://prods3.imgix.net/images/articles/2018_04/nonfeatured-peanut-butter-pickle-sandwich.jpg?auto=format%2Ccompress&ixjsv=2.2.3&w=670','https://images.food52.com/3r4VpTSTK_so4-e48J0UBQXhSJc=/753x502/0136f334-6bb5-4df0-904d-3b370633f51e--food52_pic.jpg']},{images:['https://hgtvhome.sndimg.com/content/dam/images/hgtv/fullset/2010/8/11/2/TS-78365701_pile-of-pickles_s3x4.jpg.rend.hgtvcom.616.822.suffix/1400948851006.jpeg','https://prods3.imgix.net/images/articles/2018_04/nonfeatured-peanut-butter-pickle-sandwich.jpg?auto=format%2Ccompress&ixjsv=2.2.3&w=670','https://images.food52.com/3r4VpTSTK_so4-e48J0UBQXhSJc=/753x502/0136f334-6bb5-4df0-904d-3b370633f51e--food52_pic.jpg']}];

    ngOnInit() {

        this.service.resetVariant();

        let holdThis = JSON.stringify(this.emptyProduct);
        this.product = JSON.parse(holdThis);

        let holdVar = JSON.stringify(this.emptyVariant);

        this.variants = [{val:JSON.parse(holdVar),id:null,toAdd:false}];

        this.currentVariety = {val:this.variants[0].val,id:0,toAdd:false};

        this.service.variantObs.subscribe(res=>{
        
            if(res && res.length>0)
            {
                this.variants = [];
         
                this.variants = [];
                this.variantId = [];
                res.forEach(each=>{
                    if(each)
                    {
                        let holdData = JSON.stringify(each.data());
                        // let holdId = JSON.stringify(each.id);
                        
                        let holdEach = JSON.parse(holdData);
                        if(!(holdEach.images && holdEach.images.length>0))
                        {
                            holdEach.images = ['','',''];
                        }
                        this.variants.push({val:holdEach,id:each.id,toAdd:false});
                    }

                })
                this.currentVariety = {val:this.variants[0].val,id:0,toAdd:false};
                this.validateForm();
            }
        })

        this.service.globalObs.subscribe(res=>{
            if(res)
            {
                let holdPref = JSON.stringify(res);
                this.globalPref = JSON.parse(holdPref);

            }
        })
        //

        this.service.getGlobalPref();
    }

    

    omit_special_char3(event)//for barcode
    {
      var k;
      k = event.charCode;
  
      //----to block enter submit
      var key = event.charCode || event.keyCode || 0;
      if (key == 13) {
        event.preventDefault();
      }
      //--end of block
  
  
      return (k != 32);//space is not allowed 
  
    }
  
    addvar()
    {
        let holdEmpty = JSON.stringify(this.emptyVariant);
        this.variants.push({val:JSON.parse(holdEmpty),id:null,toAdd:false});
        let last = this.variants.length;
        
        this.currentVariety = {val:this.variants[last-1].val,id:last-1,toAdd:false};
        this.validateForm();
    }
    DelVarModal(i)
    {
        let last = this.variants.length;
        this.variants.splice(i,1);
        this.currentVariety = {val:this.variants[0].val,id:0,toAdd:false};
        this.validateForm();
    }
    ngOnDestroy()
    {
       
        this.getRidofImages().then(res=>{
            this.service.resetVariant();
        })
    }
    googleS()
    {
        window.open('https://www.google.com/search?q='+this.barcode2Search,"_blank");
    }

    findProduct()
    {
        this.barcode2Search = this.barcode2Search?this.barcode2Search.trim():null;

        if(this.barcode2Search && this.barcode2Search.trim() && this.barcode2Search.trim().length>5)
        {
            this.showProduct = true;
            this.service.resetVariant();
           
            this.service.findProduct(this.barcode2Search).subscribe((res:any)=>{
               
                if(res && res.length>0)
                {
                    this.found = true;
                    let holdProd = JSON.stringify(res[0].payload.doc.data()); 
                    this.product = JSON.parse(holdProd);
                    this.toEdit = true;
                    
                    if(!(this.product.images && this.product.images.length>0))
                    {
                            this.product.images = ['','','',''];
                    }
                    this.findVariants(this.product.barCode,res[0].payload.doc.id,this.product.varRef);
                    this.productId = res[0].payload.doc.id;
                    this.validateForm();
                }
                else
                {
                    this.found = false;
                    this.toEdit = false;
                    this.productId = null;
                    let holdThis = JSON.stringify(this.emptyProduct);
                    this.product = JSON.parse(holdThis);
                    
                    let holdVar = JSON.stringify(this.emptyVariant);
                    
                    this.variants = [{val:JSON.parse(holdVar),id:null,toAdd:false}];
                    
                    this.variants[0].val.barCode = this.barcode2Search;
                    this.currentVariety = {val:this.variants[0].val,id:0,toAdd:false};
                    this.service.showOrHideSearchResult(true);
                }
                
            })
        }
           
    }

    findVariants(barcodes,id,varRef)
    {
        this.service.resetVariant();
   
            let barCount = [];
            barcodes.forEach(each=>{
               
                this.service.findVariants(each,id,varRef);
            })
           
      
    }

    varietySwitch(i)
    {
        this.currentVariety = { val:this.variants[i].val,id:i,toAdd:false};
        this.validateForm();
    }

    validateForm()
    {
  
        if (this.product && this.product.productName
            && (this.product.productName.trim().length >= 3) &&
            this.product.category && this.product.category.trim()  && this.product.brand && this.product.brand.trim()) {
           
            let count:number = 0;
            this.variants.forEach(each => {

            
                if(!(each && each.val.barCode && each.val.barCode.trim() && each.val.price  && each.val.unit && each.val.unit.trim()))
                {
                    count++;
                }
            })

            if(count>0)
            {
                this.validated = false;
            }
            else
            {
                this.validated = true;
            }
        }
        else
        {
            this.validated = false;
        }
    }
   
    toUpdate()
    {
        this.product.brand = this.product.brand && this.product.brand.trim()?this.product.brand.trim():null;
        this.product.category = this.product.category && this.product.category.trim()?this.product.category.trim():null;
        this.product.productName = this.product.productName.trim();
        
        this.variants.forEach(each=>{
            each.val.barCode = each.val.barCode.trim();
            each.val.packSize = each.val.packSize && each.val.packSize.trim()?each.val.packSize.trim():null;
            each.val.price = each.val.price+'';
            each.val.unit = each.val.unit.trim();
            if(each.val.images)
            {
               let valuer =  each.val.images.every((val)=>{
                    return !(val&& val.trim() && val.trim().length>0)
                })
                if(valuer)
                {
                    delete each.val.images; 
                 
                }
                else
                {
                    // console.log('nothing to delete');
                }
            }
        })

        if(this.product.images)
        {
           let valuer =  this.product.images.every((val)=>{
                return !(val&& val.trim() && val.trim().length>0)
            })
            if(valuer)
            {
                delete this.product.images; 
           
            }
            else
            {
                // console.log('nothing to delete');
            }
        }

        this.service.updateProduct(this.product,this.productId,this.variants,this.variantId)
        .then(res=>{
            
            this.service.resetVariant();
            this.deleteOldImages().then(res2=>{
                this.findProduct();
                this.BackupProductImages = [];
                this.BackupVariantImages = [];
                this.addPhoto = false;
                this.toCloseModal = false;
            })
            .then(res=>{
                this.toUpdate2 = true;
                this.showProduct = false;
                this.service.showOrHideSearchResult(this.showProduct);
            })
            .catch(err=>{
                //console.log('error while deleting old image backups');
                //console.log(err);
            });
            
        }).catch(err=>{
            // console.log('errror while updating the product information');
            // console.log(err);
        })
    }

    toSubmit()
    {
        this.product.brand = this.product.brand && this.product.brand.trim()?this.product.brand.trim():null;
        this.product.category = this.product.category && this.product.category.trim()?this.product.category.trim():null;
        this.product.productName = this.product.productName.trim();
        
        this.variants.forEach(each=>{
            each.val.barCode = each.val.barCode.trim();
            each.val.packSize = each.val.packSize && each.val.packSize.trim()?each.val.packSize.trim():null;
            each.val.price = each.val.price+'';
            each.val.unit = each.val.unit.trim();
            if(each.val.images)
            {
               let valuer =  each.val.images.every((val)=>{
                    return !(val&& val.trim() && val.trim().length>0)
                })
                if(valuer)
                {
                    delete each.val.images; 
                 
                }
                else
                {
                    // console.log('nothing to delete');
                }
            }

        })
        
        if(this.product.images)
        {
           let valuer =  this.product.images.every((val)=>{
                return !(val&& val.trim() && val.trim().length>0)
            })
            if(valuer)
            {
                delete this.product.images; 
   
            }
            else
            {
                // console.log('nothing to delete');
            }
        }

        

        this.product.searchKey = this.product.productName.split(' ').map(each=>{return each.toLocaleLowerCase()});
        // console.log(this.product);
        this.service.addProduct(this.product,this.variants)
        .then(res=>{
                // console.log('product added');
                // console.log(res);
            this.deleteOldImages().then(res2=>{
               // console.log('old image backups deleted');
                this.BackupProductImages = [];
                this.BackupVariantImages = [];
                this.findProduct();
                this.addPhoto = false;
                this.toCloseModal = false;

                this.toSave2 = true;
               this.showProduct = false;
               this.service.showOrHideSearchResult(this.showProduct);
            })
            .catch(err=>{
               // console.log('error while deleting backup images');
                this.findProduct();

            });
            
        })
        .catch(err=>{
           // console.log('error while adding the new product to the central database');
            //console.log(err);
        })
    }

    fetchImage(Devent, para,i,j) 
        {
            // console.log(Devent,para);
            // console.log(i,j);
            
        
            if (Devent.target.files && Devent.target.files[0]) 
            {
            //console.log(Devent.target.files[0].name);
            var size = Number(Devent.target.files[0].size);

            if (size > this.Max_Size) 
            {
                alert("Maximum file size should not exceed 300kb");
            }

            else 
            {

                this.selectedFiles = (event.target as HTMLInputElement).files;

                // ---------------------//
                const file = this.selectedFiles;
                //console.log(file);
                if (file && file.length === 1) 
                {
                this.currentUpload = new Upload(file.item(0));
                /* -------------------*/
                if(para == 'products')
                {
                    this.service.pushUpload1(this.currentUpload,para,this.productId,i).then(res1=>{
                    
                    //    console.log(res1);
                        if(res1)
                        {
                            if(!(this.BackupProductImages && this.BackupProductImages.length>0))
                            {
                            this.BackupProductImages = [{id:i,link:this.product.images[i]}];
                            this.product.images[i] = res1;
                            }
                            else
                            {
                            this.BackupProductImages.push({id:i,link:res1});
                            this.product.images[i] = res1;
                            }
                            
                        } 
                        else
                        {
                           // console.log('error while uploading file');
                        }
                    }).catch(err=>{
                        // console.log("couldn't complete the upload.try again later!");
                        // console.log(err);

                    })
                }
                    
                else if(para == 'variety')
                {
                    this.service.pushUpload2(this.currentUpload,para,this.productId,this.variants[i],j).then((res2:any)=>{
                        if(res2)
                        {
                            if(!(this.BackupVariantImages && this.BackupVariantImages.length>0))
                            {
                                this.BackupVariantImages = [{varId:i,imgId:j,link:this.variants[i].val.images[j]}];
                                this.variants[i].val.images[j] = res2.url;
                                if(res2.id)
                                {
                                    this.variants[i].id = res2.id;
                                    this.variants[i].toAdd = true;
                                }
                               
                            }
                            else
                            {
                                this.BackupVariantImages.push({varId:i,imgId:j,link:this.variants[i].val.images[j]});
                                this.variants[i].val.images[j] = res2;
                                if(res2.id)
                                {
                                    this.variants[i].id = res2.id;
                                    this.variants[i].toAdd = true;
                                }
                            }
                        }

                    })
                }
                    

                

                }        
            }
            }
        }

    updateImages()
    {
        // console.log(this.product);
        // console.log(this.variants);
        
        this.validateForm();
        if(this.validated)
        {
            if(this.toEdit)
            {
                this.toUpdate();
            }
            else
            {
                this.toSubmit();
            }
        }
        else
        {
            alert('you left some field unfilled.fill it & try again.');
        }

    }
  deleteOldImages()
  {
    let allPromise = [];
   if(this.productId)
   {
        //deleting productbackupImages --
      if(this.BackupProductImages && this.BackupProductImages.length>0)
      {
        this.BackupProductImages.forEach(each=>{
                // console.log('--product--');
                // console.log(this.product.images[each.id].split('?')[0]);
                // console.log('backup product image');
                // console.log(each.link.split('?')[0]);
                // console.log('--product ends--');

              if(this.product.images[each.id].split('?')[0] != each.link.split('?')[0])
              {
                //   console.log('this is the case');
                allPromise.push(this.service.deleteOldImages(this.productId,each.link,'products'));
              }
              else
              {
                 // console.log('match product image case');
              }

              //console.log('---------product ends--------------')

            })
 
       
      }
      else
      {
        //console.log('no product image backup to delete');
      }

    //-- deleting variant backupimages -- 
    
      if(this.BackupVariantImages && this.BackupVariantImages.length>0)
      {
          
          this.BackupVariantImages.forEach(each=>{
                // console.log('--variant--');
                // console.log(this.variants[each.varId].val.images[each.imgId].split('?')[0]);
                // console.log('in backup');
                // console.log(each.link.split('?')[0]);
                // console.log('--variant ends here'); 

              if(this.variants[each.varId].val.images[each.imgId].split('?')[0] != each.link.split('?')[0])
              {
               allPromise.push(this.service.deleteOldImages(this.productId,each.link,'variety'));
              }
              else
              {
                 // console.log('match variant image case');
              }

              //console.log('======variant ends here==========');
                
           })
      }
      
      else
      {
          //console.log('no variant image backup to delete');
      }

    
   }

   return Promise.all(allPromise).then(res=>
    {
        //console.log('all resolved in delete');
        return res;
    }).catch(err=>{
        //console.log('error while deleting');
    })
    
  }

  closeModal()
  {
   
    if((this.BackupProductImages && this.BackupProductImages.length>0 )||(this.BackupVariantImages && this.BackupVariantImages.length>0))
    {
        this.toCloseModal = true;
        // alert('please save the uploaded images');
       
       
    }
    else
    {
        this.addPhoto = false;
        this.toCloseModal = false;
    }
    
  }

  swapBackUp()
  {
      if(this.BackupProductImages && this.BackupProductImages.length>0)
      {
        this.BackupProductImages.forEach(each=>{
            let hold = this.product.images[each.id];
            this.product.images[each.id] = each.link;
            each.link = hold;
        })
      }
      if(this.BackupVariantImages && this.BackupVariantImages.length>0)
      {
        this.BackupVariantImages.forEach(each=>{
            let hold = this.variants[each.varId].val.images[each.imgId]; 
            this.variants[each.varId].val.images[each.imgId] = each.link;
            each.link = hold;
        })
      }
      
      this.getRidofImages().then(res=>{
        this.BackupProductImages = [];
        this.BackupVariantImages = [];
        this.addPhoto = false;
        this.toCloseModal = false;

      })
      .catch(err=>{
          //console.log(err);

      });
    
  }

  getRidofImages()
  {
    let allPromise = [];
   if(this.productId)
   {
        //deleting productbackupImages --
      if(this.BackupProductImages && this.BackupProductImages.length>0)
      {
        this.BackupProductImages.forEach(each=>{
             
                allPromise.push(this.service.deleteOldImages(this.productId,each.link,'products'));
            })
      }
      else
      {
        //console.log('no product image backup to delete');
      }

    //-- deleting variant backupimages -- 
    
      if(this.BackupVariantImages && this.BackupVariantImages.length>0)
      {
          
          this.BackupVariantImages.forEach(each=>{

               allPromise.push(this.service.deleteOldImages(this.productId,each.link,'variety'));
             // console.log('======variant ends here==========');
                
           })
      }
      
      else
      {
         // console.log('no variant image backup to delete');
      }

    
   }

   return Promise.all(allPromise).then(res=>
    {
        //console.log('all resolved in delete');
        return res;
    }).catch(err=>{
        //console.log('error while deleting');
    })
    
  }

   showMessage(msg:string)
   {
     alert(this.barcode2Search);
   }
   destroyCurrent(newOne:boolean)
   {
    this.barcode2Search = null;
    this.found = false;

       if(newOne)
       {
            this.toSave2 = false;
       }
        else
        {
            this.toUpdate2 =false;
        }   
       
   }
   addPhotos()
   {
     this.toSave2 = false; //to close the modals
     this.toUpdate2 = false;
     this.findProduct();//to search
     this.addPhoto = true;//to add photo
   }
   
  }