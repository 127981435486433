<br>
<br>
<div class="content-container">
  <div class="content-area" style="text-align:center">
    <br>
    <br>
    <div id='ppHeader'>Get In Touch With Us</div>
    <br>
    <br>
    <div>
      <clr-icon shape="phone-handset" class="is-solid"></clr-icon> +91 8861797773
      <br>
      <clr-icon shape="envelope" class="has-badge is-solid"></clr-icon> support@counttrees.com
    </div>
    <br>
    <br>

    <div id='ppHeader'>Frequently Asked Questions</div>
    <br>

    <clr-tree-node>
      <b>BILLER</b>
      <ng-template [clrIfExpanded]="true">
        <clr-tree-node>
          <b>How to generate Estimate/Quotation Bill?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify">Click on Paper icon on the top right side of Action bar. On a single simple click you can make estimate bills
                now.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to add inventory?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%"> Click on Inventory icon on the bottom right side of the home page, then click on Add (+) button. Fill the product
                details along with image and click on save button to add your product to your inventory and click on save
                & Add button to save the product and add next product.</span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to add variety of Product?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">Every products have some varieties. You can add varieties by just clicking on (+) Button on the right bottom
                side of the add item page.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to remove variety of Product?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                You can easily remove variety of product by simply clicking on Red Cross mark on Add item page.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to enable/disable of product details parameter like MFG, Exp. date, Discount, Stock, Purchase Price, Product
            Info and Barcode?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                Enable/Disable of product details is easily done by clicking on setting button on the top right side on the Add item page
                then by unchecking the unwanted details text fields you can remove those fields.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to edit/remove of product from inventory list?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                Click on Inventory icon on the bottom right side of the home page, in the inventory list, click on the <clr-icon shape="ellipsis-horizontal"></clr-icon>icon on top
                right corner of the card of the product. You will find edit and delete option.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to give Discount on overall Bill?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                After adding items in the billing page, press and hold the (+) button and then click on discount on bills.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to reset the bill?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                After adding items in the billing page, press and hold the (+) button and then click on Reset Bill.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to generate Draft bill?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                After adding items in the billing page, press and hold the (+) button and then click on Draft Bill.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>Where to find Draft bills?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                Click on
                <clr-icon shape="bars"></clr-icon> icon or Swipe left to right in the biller app to open the navigation drawer and then click on
                draft bills.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to do Store details setting?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                Click on
                <clr-icon shape="bars"></clr-icon> icon or Swipe left to right in the biller app to open the navigation drawer, then click on Setting
                and then click on store setting.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to do Inventory setting?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                Click on
                <clr-icon shape="bars"></clr-icon> icon or Swipe left to right in the biller app to open the navigation drawer, then click on Setting
                and then click on Inventory setting.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to make your store online!</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                As soon as you register with us you are online. Add items to your inventory with all details and with good Quality photos.
                You web store will be ready in no time.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to analyze your store report?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                Click on Report icon at bottom bar of biller app in home page. You can Analyze total sell details with taxation.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>Where can I search my bills?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                By clicking on search icon at top menu bar in biller home page.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to search our Bills?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                Many ways to search your bills.
                <li>Search through recent bills.</li>
                <li>Search through mobile numbers</li>
                <li>Search through Invoice number.</li>
                <li>Search through date range.</li>
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>Different type of searched bills?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                Various type of search mechanisms are there like Recent Bills, Today's Bills and Search through Mobile Number, Invoice Number
                and Date range.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to send a drafted bill?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                Swipe left to right in the biller app to open the navigation drawer, then click on Draft Bills. Then drafted bill will open
                as a current bill in billing page where you can add and remove items.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to see details of a bill?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                Search for the bill in search section and click on desired bill, you can see full details of that particular bill.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to add items to generate bills?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                You can add item by clicking (+) icon in the billing page, then you can add items from the inventory by clicking increment
                (+) or decrement (-) button from product card. Then click on back button in the top left or calculator icon
                or cart icon on the top right of add inventory page to go back to billing page.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to send bills to customer?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                After adding items in the billing page click on send button on top right corner, enter the mobile no of the customer and
                click on send button.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to edit/remove items from billing page or bill generate interface?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                <li> In order to edit an item in the billing page itself just Swipe left to right on the item name.</li>
                <li> In order to delete an item from the billing page just swipe right to left on the item name. </li>
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to set store footer?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                You can add content to footer section by clicking on footer edit in store setting.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to hide/show store header?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                Click on hide unhide eye type icon on the top right side of header section on the billing page to hide and unhide the header.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to send Summary bills?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                You can also send your bill of customize item by simply clicking on "Add Summary item".
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>
      </ng-template>
    </clr-tree-node>

  
    <br>
    <clr-tree-node>
      <b>BILLWALLET</b>
      <ng-template [clrIfExpanded]="true">

        <clr-tree-node>
          <b>How to get bills in BillWallet?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                When bill is sent from biller app, the customer will get that bill as SMS & in BillWallet too.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>If User doesn’t have BillWallet app and keeps on receiving bills as SMS, How the user will get those bills in BillWallet
            app.
          </b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                No need to worry whenever the user will download BillWallet all bills will automatically gets synced. User will not lose
                any bills.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to get bill in PDF?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                Open a bill in BillWallet and click on PDF icon on top right side of the app.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to share a bill?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                Open a bill in BillWallet and click on share icon on top right side of the app.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to see full bill details?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                Click on bill that you want to see in details.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to check an items details?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                In order to see item details in a bill, swipe right to left in the bill content.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>
      </ng-template>
    </clr-tree-node>

    
    <br>
    <clr-tree-node>
      <b>PUPAPE</b>
      <ng-template [clrIfExpanded]="true">

        <clr-tree-node>
          <b>How to register a store in Pupape?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                Follow the steps:
                <li>Download Biller app.</li>
                <li>Create your inventory by adding new items to your inventory.</li>
                Your Pupape store is automatically created. No separate registration required.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>Which type of store you can register with Pupape?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                Any type of stored can be registered with Pupape.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How to edit item's details on Pupape?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                You can edit item’s details on Pupape by editing item details in the inventory of biller app. Still confused then follow
                the steps:
                <li>Open biller app</li>
                <li>Click on Inventory icon on the bottom right side of the home page</li>
                <li>Click on the three dots on top right corner on the card of the product you want to edit.</li>
                <li>You will find edit and delete option, then click on edit and do your changes.</li>
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

        <clr-tree-node>
          <b>How Pupape help a store?</b>

          <ng-template [clrIfExpanded]>
            <clr-tree-node>
              <span style="text-align:justify; line-height:140%">
                Pupape makes your shop online. You can share your entire products lists with all varieties and detailed price to the customer
                in a single click.
              </span>
            </clr-tree-node>
          </ng-template>
        </clr-tree-node>

      </ng-template>
    </clr-tree-node>
  </div>
</div>