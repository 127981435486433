<footer>
    <div class="footer-wrapper">
        <div class="clr-row">
            <div class="clr-col-lg-4 clr-col-md-4 clr-col-sm-4 clr-col-xs-12">
                <span>
                    <clr-icon shape="envelope"></clr-icon>
                    <a href="mailto:admin@counttrees.com" style="color:black;"> admin@counttrees.com</a>
                     <br>
                    <clr-icon shape="mobile"></clr-icon>
                    <a href="tel:+918861797773" style="color:black;"> +91 88617 97773</a>
                </span>
            </div>
            <div class="clr-col-lg-4 clr-col-md-4 clr-col-sm-4 clr-col-xs-12">
                <span class="copyright">
                   <a target="_blank" href="http://www.counttrees.com" style="color:bisque">Counttrees Technologies Private Limited</a> 
                  
                   <br>All Rights Reserved © 2016 - 2020
                </span>
                <br>
            </div>
            <div class="clr-col-lg-4 clr-col-md-4 clr-col-sm-4 clr-col-xs-12">
                <a href="https://goo.gl/maps/GP9hPinir7yj2C6Y9"
                    target="blank" style="color:black;">
                    MAP
                </a>
                &nbsp;&nbsp;&nbsp;
                <a href="https://biller.counttrees.com/docs/who_can_use" target="blank" style="color:black;">
                    FAQ
                </a>
                <br>
                <a class="nav-link" href="#" [routerLink]="['/privacypolicy']" (click)="scrolltop()" routerLinkActive="active" style="color:black;">
                  <span class="nav-text">Privacy Policy</span>
                </a>
            </div>
        </div>
    </div>
</footer>