    <div class="card-header">
        <span *ngIf="toEdit">
            Edit an Item from the central Product Database
        </span>
        <span *ngIf="!toEdit">
            Add an Item to the central Product Database
        </span>
    </div>

    <div class="card-block">
        <form class="form-compact">
            <div class="row form-block" >
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <!-- <input type="text" [(ngModel)]="barcode2Search" name="barcode2Search">
                    <i style="color:red;display:block;" *ngIf="!(this.barcode2Search && this.barcode2Search.trim() && this.barcode2Search.trim().length>5)">type a barcode to search</i> -->
                    <!-- -->
                    <div class="form-group">
                        <label class="title">Barcode</label>
                        <label for="barcode" aria-haspopup="true" role="tooltip" class="tooltip tooltip-top-left tooltip-validation tooltip-sm"
                            [class.invalid]="!(this.barcode2Search && this.barcode2Search.trim() && this.barcode2Search.trim().length>5)">
                            <input type="text" [(ngModel)]="barcode2Search"
                                placeholder="type barcode" style="border-bottom:none;width:90%;" pattern="^[a-zA-Z0-9_ ]*$"
                                id="barcode" name="barcode" required>
                            <span class="tooltip-content">
                                Can't be empty
                            </span>
                            <!-- <span *ngIf="!storeInfo.header.storeName" title="No store name!"><clr-icon shape="info-standard" style="color:red;"></clr-icon></span> -->
                        </label>
                        <!---->
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary" (click)="findProduct()" 
                            [disabled] ="!(this.barcode2Search && this.barcode2Search.trim() && this.barcode2Search.trim().length>5)">
                            <!-- <clr-icon shape="search"></clr-icon> -->
                            search
                        </button>
                        <button class="btn btn-primary" (click)="googleS()"
                            [disabled] ="!(this.barcode2Search && this.barcode2Search.trim() && this.barcode2Search.trim().length>5)">
                            google it
                        </button>
                    </div>
                    
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3 [class.red]="!found" [class.green]="found">{{found?'product found':'no product found'}}</h3>
                </div>
                <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="!showProduct">
                    <clr-icon shape="search" size="48"></clr-icon>
                    <h3>Search Results</h3>
                </div> -->
            </div>
        </form>
        <form class="form compact" *ngIf="showProduct">
        <!-- <div class="row" *ngIf="showProduct"> -->
               
            
                        <div class="row form-block" *ngIf="showProduct">
                           <!--<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <input type="text" [(ngModel)]="barcode2Search" name="barcode2Search">
                                <button class="btn btn-primary" (click)="findProduct()" >getResults</button>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="!showProduct">
                                <clr-icon shape="search" size="48"></clr-icon>
                                Search Results
                            </div> -->
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="showProduct" style="text-align: justify;">
                                        <div class="form-group">
                                            <label for="name">Product Name
                                                <sup style="font-size:12px;color:red;">*</sup>
                                            </label>
                                            <label for="name" aria-haspopup="true" role="tooltip" class="tooltip tooltip-top-left tooltip-validation tooltip-sm"
                                                [class.invalid]="!(product && product.productName 
                                                    && (product.productName.trim().length >= 3))">
                
                                                <input type="text" [(ngModel)]="product.productName" name="name" (keyup)="validateForm()"
                                                    placeholder="enter product name" maxlength="50" >
                                                <span class="tooltip-content">
                                                    at least 3 characters
                                                </span>
                                            </label>
                
                                        </div>
                                    
                                        <div class="form-group" >
                                            <label for="brand">Brand
                                                <sup style="font-size:12px;color:red;">*</sup>
                                            </label>
                                            <input type="text" [(ngModel)]="product.brand" 
                                                name="brand" maxlength="50"
                                                placeholder="enter brand name" (keyup)="validateForm()">
                                                
                                        </div>
                          
                                        <div class="form-group">
                                            <label for="category">Category
                                                <sup style="font-size:12px;color:red;">*</sup>
                                            </label>
                                           
                                            <div class="select" *ngIf="globalPref && globalPref.categoryList">
                                                <select id="category" [(ngModel)]="product.category" name="category" (change)="validateForm()">
                                                    <option *ngFor="let cat of globalPref.categoryList" value={{cat}} 
                                                    [selected]= " cat == product.category">
                                                        {{cat}}
                                                    </option>
                                                   
                                                </select>
                                            </div>
                                        </div>
                                     
                                      
                                <dl>
                                    <dt>
                                        <a style="text-decoration: none;">
                                           
                                            <div style="width:100%;">
                                              
                                                <button class="btn btn-link" style="float:right;" (click)="addvar()" >
                                                    +Add Variant
                                                </button>
                                            </div>
                                        </a>
                                    </dt>
                                    <dd  style="margin-inline-start:0px;">
                                        <div style="width:100%;">
                                            
                                            <br>
                                          
                                            <div class="row" style="display:flex;" *ngIf="variants.length<=4">
                                                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6" *ngFor="let variety of variants;let i = index;">
                                                    <button class="btn all" (click)="varietySwitch(i)" [class.selected]="i == currentVariety.id"
                                                        style="max-width:100%;white-space: nowrap; 
                                                        overflow: hidden;text-overflow: ellipsis;">
                                                        {{variety && variety.val && variety.val.packSize?variety.val.packSize:''}}
                                                        &nbsp;{{variety && variety.val && variety.val.unit?variety.val.unit:'Unit'}}
                
                                                    </button>
                                                    <br>
                                                </div>
                                            </div>
                                            <div class="select" *ngIf="variants.length>4" style="width:40%;">
                                                <select #varVal (change)="varietySwitch(varVal.value)">
                                                    <option *ngFor="let variety of variants;let ju = index;" value={{ju}}
                                                        [selected]="ju == currentVariety.id">
                                                        {{variety && variety.val && variety.val.packSize?variety.val.packSize:''}}
                                                        &nbsp;{{variety && variety.val && variety.val.unit?variety.val.unit:'Unit'}}
                                                    </option>
                                                </select>
                                            </div>
                                            
                                            <div class="form-group">
                                                <label for="packSize">Variant
                                                    <sup style="font-size:12px;color:red;">*</sup>
                                                </label>
                                                <input type="text" name="packSize" [(ngModel)]="currentVariety.val.packSize" maxlength="6"
                                                    (keyup)="validateForm()" 
                                                     [class.erroro]="!( currentVariety.val.packSize 
                                                        && currentVariety.val.packSize.trim().length>0)"
                                                    placeholder="enter variant">
                
                                            </div>
                                           
                                            <div class="form-group">
                                             
                                                <label for="unit">
                                                    Unit
                                                    <sup style="font-size:12px;color:red;">*</sup>
                                                </label>
                                                <div class="select" *ngIf="globalPref && globalPref.unitList">
                                                    <select id="unit" [(ngModel)]="currentVariety.val.unit" name="unit" (change)="validateForm()"
                                                    [class.erroro2]="  !(currentVariety.val.unit && currentVariety.val.unit!= 'add') ">
                                                         <option *ngFor="let Iunit of globalPref.unitList" value={{Iunit}} 
                                                            [selected]="Iunit == currentVariety.val.unit">
                                                            {{Iunit}}
                                                        </option>
                                                      
                                                    </select>
                                                </div>
                                            </div>
                
                                            <div class="form-group">
                                                
                                                <label for="price">Sales Price(&#8377;)
                                                    <sup style="font-size:12px;color:red;">*</sup>
                                                </label>
                                                <input type="number" [(ngModel)]="currentVariety.val.price" name="price" (keyup)="validateForm()"
                                                    placeholder="enter sale price" 
                                                    [class.erroro]="!(currentVariety.val.price && currentVariety.val.price != '0')">
                                                    
                                            </div>
                
                                            <div class="form-group">
                                                <label for="barCode">Bar Code
                                                    <sup style="font-size:12px;color:red;">*</sup>
                                                </label>
                                               
                                                <input type="text" [(ngModel)]="currentVariety.val.barCode" name="barCode" maxlength="21"
                                                    (keypress)="omit_special_char3($event)" placeholder="barcode number" (keyup)="validateForm()">
<!--                 
                                                <i title="space not allowed">
                                                    <clr-icon shape="info-circle"></clr-icon>
                                                </i> -->
                                            </div>
                                           
                                            <!-- <div class="form-group"> -->
                                                <div class="btn-group btn-primary">
                                                    <button type="button" class="btn btn-primary" (click)="addPhoto = true;" [disabled]="!(toEdit && validated)">Add Photos</button>
                                                    <button type="button" class="btn btn-primary" *ngIf="!toEdit" [disabled]="!validated"
                                                        (click)="toSubmit()">
                                                        SAVE
                                                    </button>
                                                    <button type="button" class="btn btn-primary"   *ngIf="toEdit" [disabled]="!validated"
                                                        (click)="toUpdate()">
                                                        UPDATE
                                                    </button>
                                                </div>
                                            <!-- </div> -->
                                            
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                   
        <!-- </div> -->
        </form>
    </div>

    <!-- modal for new tax-->
    <div style="z-index:200;position:fixed;top:0;bottom:0;left:0;right:0;height:100%;width:100%;
          background-color:#343434;overflow:scroll;padding:5%;" 
            *ngIf="addPhoto" > 

    <div class="row" style="background-color:white;padding:2%;" >
        
        <div class="col-sm-12 text-left" style="margin-top:10px;margin-bottom:10px;">
            <button  class="btn btn-primary" (click)="closeModal()">
                <clr-icon shape="angle" dir="left"></clr-icon> Back
            </button>
        </div>
       
        <!-- <div class="col-sm-2"></div> -->
        <div class="col-sm-12 col-xs-12">
            <h3>Product Images</h3>
            <div class="row" style="height:200px;margin-bottom:10px;">
                <div class="col-sm-3" *ngFor="let image of product.images;let i = index;" style="height:100%;">
                    <img [src]="image" alt="image{{i}}" style="width:100%;height:100%;position:absolute;z-index:0;left:0px;opacity:0.7;" accept="image/*">
                    <input type="file" style="height:100%;width:100%;position:absolute;z-index:1;left:0px;" (change)="fetchImage($event,'products',i)">
                </div>
            </div>
            <h3>Variant Images</h3>
            <div class="row" style="height:200px;margin-top:10px;" *ngFor="let onevar of variants;let i=index;">
                <div class="col-sm-3">{{onevar.val.packSize}} &nbsp; {{onevar.val.unit}} </div>
                <div class="col-sm-3" *ngFor="let image of onevar.val.images;let j = index;" style="height:100%;">
                    <img [src]="image" alt="image{{j}}" style="width:100%;height:100%;position:absolute;z-index:0;left:0px;opacity:0.7;" accept="image/*">
                    <input type="file" style="height:100%;width:100%;position:absolute;z-index:1;left:0px;" (change)="fetchImage($event,'variety',i,j)">
                </div>
            </div>
            <button type="button" (click)="updateImages()">Update Images</button>
           
        </div>
        <!-- <div class="col-sm-2"></div> -->
    </div>
</div>

<div class="modal static bump-down" [hidden]="!toSave2">
    <div class="modal-dialog" role="dialog" aria-hidden="true">
        <div class="modal-content">
            <div class="modal-header">
                <clr-icon shape="check" size="60" style="color:green;"></clr-icon>
                <h5 class="modal-title">Item Saved</h5>
            </div>
            <div class="modal-body text-center">
                <!-- <p style="font-size:18px;"> Are you sure ? </p> -->

                <div class="btn-group btn-outline" style="margin-top:10px;">
                    <button class="btn btn-success" (click)="addPhotos()">Add Photo</button>
                    <button class="btn btn-danger" (click)="destroyCurrent(true)">Add new Item</button>
                    <!-- <button class="btn" (click)="toSave2 =false;">Close Modal</button> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="toSave2" class="modal-backdrop fade in" aria-hidden="true"></div>


<div class="modal static bump-down" [hidden]="!toUpdate2">
        <div class="modal-dialog" role="dialog" aria-hidden="true">
            <div class="modal-content">
                <div class="modal-header">
                    <clr-icon shape="check" size="60" style="color:green;"></clr-icon>
                    <h5 class="modal-title">Item updated</h5>
                </div>
                <div class="modal-body text-center">
                    <!-- <p style="font-size:18px;"> Are you sure ? </p> -->
    
                    <div class="btn-group btn-outline" style="margin-top:10px;">
                        <button class="btn btn-success" (click)="addPhotos()">Add Photo</button>
                        <button class="btn btn-danger" (click)="destroyCurrent(false)">Add new Item</button>
                        <!-- <button class="btn" (click)="toUpdate2 =false;">Close Modal</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="toUpdate2" class="modal-backdrop fade in" aria-hidden="true"></div>
