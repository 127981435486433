<br>
<br>
<div class="content-container">
    <div class="content-area">
        <div id='ppHeader'>Biller Terms and Conditions</div>
        <br>
        <br>
        <div id='ppBody'>
            <div class='innerText'>Biller is committed to protecting your privacy and this privacy statement describes 
              in greater detail the privacy practices of Counttreees Technologies Private Limited.
                <br>
              </div>
              <span id='infoCo'></span>
              <br>
              <div class='grayText'>
                <strong>General</strong>
              </div>
              <br />
              <div class='innerText'>Review these Terms of Service ("Agreement") thoroughly. This Agreement is a legal agreement 
                between the User and Biller. By accepting electronically (for example: clicking "Log in and proceed"), installing, 
                accessing or using the Services, User agrees to these terms. If the User does not agree to this Agreement, then he/she 
                may not use the Services. This License Agreement shall be governed by and construed in accordance with the laws of the 
                INDIA.</div>
              <br>
              <div class='grayText'>
                <strong>Modification of terms & conditions</strong>
              </div>
              <br />
              <div class='innerText'>These terms may be updated from time to time in the future. The User should agree to review our 
                Terms and Conditions regularly by visiting this page. Continued access to or use of the service will mean that the User agrees 
                to the change.</div>
              <br>
              <div class='grayText'>
                <strong>When This Privacy Statement Applies</strong>
              </div>
              <br />
              <div class='innerText'>Its liability to User is limited to those who has downloaded the Biller application by clicking on to the "Login" button for Terms and Conditions.</div>
              <br>
              <div class='grayText'>
                <strong>Description of Services</strong>
              </div>
              <br />
              <div class='innerText'>We provide an array of services including, Invoicing, Reporting Tool, Inventory Management, Estimate
                /Order Form creator and so on to serve as a complete business management Software ("Service" or "Services").
                The User may use the Services for his/her personal and business use or for internal business purpose in the organization that 
                he/she represent. <br> 
                The User may download the application Google Play Store using the Internet. Once downloaded, User need internet 
                connection for its usage. User is responsible for obtaining access to the Internet and the device necessary to use the Services. <br> 
                (Note: Biller tries its best to make Businesses compliant to the taxation and other laws as much as possible. But, it is solely the 
                User's responsibility to see to that he/she remains compliant with GST and other laws levied by Government. Biller is not in any 
                case responsible with the violation of the compliance caused by software)</div>
            <br>
            <div class='grayText'>
              <strong>Business Data and Privacy</strong>
            </div>
            <br />
            <div class='innerText'>The User alone is responsible for maintaining the confidentiality of his/her username, password and 
              other sensitive information. He/She is responsible for all activities that occur in his/her user account and he/she 
              agrees to inform us immediately of any unauthorized use of their user account by email to admin@counttrees.com or by 
              calling us on any of the numbers listed on <a href="https://counttrees.com/home">https://counttrees.com/home.</a> We are not responsible for any loss or damage 
              to his/her or to any third party incurred as a result of any unauthorized access and/or use of his/her user account, or 
              otherwise. We are neither responsible for any kind of data loss as performing necessary backups on data is solely the User's responsibility. 
              Biller is not responsible for any kind of data discrepancy or any type of loss occurred due to data discrepancy/software issue.</div>
            <br>
            <div class='grayText'>
              <strong>Data Ownership</strong>
            </div>
            <br />
            <div class='innerText'>The User owns the content created or stored by his/her as we respect his/her right to ownership of 
              content created or stored by them. The Users use of the Services does grant Biller the license to use, reproduce, adapt, 
              modify, publish or distribute the content created by them or stored in the user's account for Biller internal purpose. 
              Biller tries keeping Users data safe but holds zero responsibility in case of data leaks.</div>
            <br>
            <div class='grayText'>
              <strong>Permissions</strong>
            </div>
            <br />
            <div class='innerText'>
              <ul>
                <li>Camera - To take pictures of products while adding inventory</li>
                <li>Contacts -  Contacts to pre-populate the details during Invoice creation and Customer/Party creation</li>
                <li>Location - To Choose your Store Address while enabling your online store</li>
                <li>Storage - To download xlsx, pdf reports etc</li>
              </ul>
            </div>
            <br>
            <div class='grayText'>
              <strong>Approval of Alerts, Messages and Promotional Notifications</strong>
            </div>
            <br />
            <div class='innerText'>In addition to the General Conditions of Biller service a User agrees to be bound by the 
              following terms and conditions:</div>
            <br>
            <div class='grayText'>
              <strong>Permitted Users:</strong>
            </div>
            <br />
            <div class='innerText'>By using this application you agree that you are at least 16 years old, and if you're not sure, request help 
              from your parents or guardians before you start creating an account.</div>
              <ol type="i">
                <li>The User agrees to receive promotional, transactional and commercial communications from Biller through Calls, E-mail, SMS or Push Notifications.</li>
                <li>Under certain conditions, promotional messages are transmitted to the users and that Biller shall not bear any further obligation in respect of such promotional messages.</li>
              </ol>
            <br>
            <div class='grayText'>
              <strong>Cancellation/Refund Policy:</strong>
            </div>
            <ol type="i">
              <li>If the user is not satisfied with the product or service and want to discontinue, per month bill for the used time period will be calculated and remaining amount will be refunded.</li>
              <li>No refund will be given after the license has been used for more than 50% of the subscription time</li>
              <li>The refund request should be raised via E-mail or website.</li>
              <li>Refund policies will be applicable only for purchases equal to or more than 1 year.</li>
            </ol>
            <br />
            <div class='innerText'>You cannot cancel your subscription, your payment is non-refundable and the subscription will continue 
              until your contracted term.</div>
            <br>
            <div class='grayText'>
              <strong>End of Terms of Service</strong>
            </div>
            <br />
            <div class='innerText'>If you have any questions or concerns regarding this Agreement, please contact us at <a href='mailto:hr.admin@counttrees.com'> admin@counttrees.com</a>
              Biller billing app belongs to Counttress Technologies Private Limited.</div>
            <br>
            <div class='blueText'>
              <strong>Contacting Us</strong>
            </div>
            <a href="https://counttrees.com/home">www.counttrees.com</a>
            <div class='innerText'>#26, Floor-2, Sankalpa Pearl City, Varthur</div>
            Bengaluru, Karnataka 560087
            <div class='innerText'>India</div>
            <div>
                <clr-icon shape="envelope" class="is-solid"></clr-icon>
                <a href='mailto:hr.admin@counttrees.com'> admin@counttrees.com
                </a>
            </div>
        </div>
        <div class='innerText'><clr-icon shape="mobile"></clr-icon> <a href="tel:+918861797773"> +91 88617 97773</a></div>
        <div class='innerText'>
            <br>Last Edited on 16th December, 2020</div>
    </div>
</div>