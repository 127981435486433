<div class="card-header">
    <span>
        Internet is Disconnected
    </span>
    
</div>

<div class="card-block " style="color:red;">
        <clr-icon shape="no-wifi" size="48"></clr-icon>
    <h1>Please connect to internet & try again</h1>
</div>        