import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { environment } from 'environments/environment';
import { ClarityModule } from '@clr/angular';
import { AppComponent } from './app.component';
import { ROUTING } from "./app.routing";
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { HttpClientModule } from '@angular/common/http';
import { productOperationsModule } from './ProductOperations/productOperations.module';
import { HomeComponent } from "./home/home.component";
import { AboutComponent } from "./about/about.component";
import { CareersComponent } from './careers/careers.component';
import { FooterComponent } from './footer/footer.component';
import { BlogComponent } from './blog/blog.component';
import { SupportComponent } from './support/support.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { tutorialComponent } from './tutorial/tutorial.component';

import { ProductOperationsService } from "./ProductOperations/productOperations.service";
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';


@NgModule({
    declarations: [
        AppComponent,
        AboutComponent,
        HomeComponent,
        CareersComponent,
        FooterComponent,
        BlogComponent,
        SupportComponent,
        PrivacyPolicyComponent,
        tutorialComponent,
        TermsConditionsComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ClarityModule,
        AngularFireModule.initializeApp(environment.firebaseCounttrees),
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        ROUTING,
        productOperationsModule
    ],
    providers: [ProductOperationsService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
