import { CommonModule} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ClarityModule } from '@clr/angular';

import { ProductOperationsComponent } from './productOperations.component';
import { ROUTING } from "./productOperations.routing";

import { EditProductComponent} from './editProduct/editProduct.component';
import { NoConnectionComponent} from './noConnection/noConnection.component';

import { ProductOperationsService} from './productOperations.service';

import { GoogleSearchComponent} from './googleSearch/googleSearch.component';


@NgModule({
    declarations: [
       ProductOperationsComponent,
       EditProductComponent,
       NoConnectionComponent,
       GoogleSearchComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        ClarityModule,
        ROUTING
    ],
    exports:[ProductOperationsComponent, EditProductComponent,NoConnectionComponent,GoogleSearchComponent],
    providers: [ProductOperationsService],
    bootstrap: [ProductOperationsComponent]
})
export class productOperationsModule {
}
