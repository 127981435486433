import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
//import  'rxjs/add/observable/combineLatest'
import * as firebase from 'firebase';
import { AngularFirestore} from '@angular/fire/firestore';
//import 'rxjs/add/operator/toPromise';
import { Variant } from './model/variant';
import { Product } from './model/product';
import { Upload } from './model/upload';



@Injectable()
export class ProductOperationsService {

  private productSearchResult = new BehaviorSubject<boolean>(false);
  searchResult = this.productSearchResult.asObservable();

  private variant = new BehaviorSubject<any>(null);
  variantObs = this.variant.asObservable(); 

  private globalPref = new BehaviorSubject<any>(null);
  globalObs = this.globalPref.asObservable();

  constructor(public FireS:AngularFirestore) {
  }


  showOrHideSearchResult(val:boolean)
  {
    this.productSearchResult.next(val);
  }
  getGlobalPref()
  {
    this.FireS.collection('GlobalPreference').doc('v5DR05Rq994ekcxPcKbR').valueChanges().subscribe(res=>{
        if(res){
            this.globalPref.next(res);
        }
    })
  }

  findProduct(barcode)
  {
        this.resetVariant();
        return this.FireS.collection('products', ref => ref.where('barCode', 'array-contains', barcode)).snapshotChanges();
           
  }
  findVariants(each,id,varRef)
  {
     

    // console.log(each);
    // console.log(id);
    // console.log(varRef);
    // console.log(varRef[each]);
  
     this.FireS.collection('products').doc(id).collection('variety').doc(varRef[each]).snapshotChanges().subscribe(res=>{
        // console.log(res.payload);
         let varietyData = this.variant.getValue();
         if(varietyData)
         {
            varietyData.push(res.payload);
         }
         else
         {
             varietyData = [res.payload];
         }
         
         this.variant.next(varietyData);
     });
     

     
  }

  resetVariant()
  {
      this.variant.next(null);
  }

    updateProduct(product:Product,productId:string,variants:{val:Variant,id:string,toAdd:boolean}[],variantId:string[])
    {
       let allPromise = [];

        allPromise.push(this.FireS.collection('products').doc(productId).set(product));
        
        variants.forEach(var1=>{
            if(var1.id && var1.toAdd == false)
            //updating existing variant
            {
                allPromise.push(this.FireS.collection('products').doc(productId).collection('variety').doc(var1.id).set(var1.val));
            }
            else if(var1.id && var1.toAdd == true)
            //new variant in case of no image
            {
                allPromise.push(this.updateVariant1(productId,var1.val,product,var1.val.barCode,true,var1.id));
            }
            else
            //new variant being added
            {
                allPromise.push(this.updateVariant1(productId,var1.val,product,var1.val.barCode,false));
            }
            
        })

       return Promise.all(allPromise).then(res=>{
         //console.log('all resolved');    
         return res;

       })
       .catch(err=>{
           // console.log(err);
        })
    }

    updateVariant1(productId,val,product,barcode,toAdd,id?)
    {
        if(toAdd && id)
        {
            return this.FireS.collection('products').doc(productId).collection('variety').doc(id).set(val).then(res=>{
                return this.updateProduct2ndTime(product,productId,id,barcode);
            })   
        }
        else
        {
            return this.FireS.collection('products').doc(productId).collection('variety').add(val).then(res=>{        
                return this.updateProduct2ndTime(product,productId,res.id,barcode);
            });
        }
    }
    updateProduct2ndTime(product,productId,variantId,barcode)
    {
        let productNew = product.varRef;
        let barNew = product.barCode;
        barNew.push(barcode);
        productNew[barcode] = variantId;
        product.varRef = productNew;
        product.barCode = barNew;
        return this.FireS.collection('products').doc(productId).update(product);
    }

    addProduct(product,variants)
    {
       return this.FireS.collection('products').add(product).then(res=>{
            let arrVar = [];
            variants.forEach(each=>{
                arrVar.push(this.addVariant(res.id,product,each))
            })
            
            return Promise.all(arrVar).then(res=>{
                return res;
            }).catch(err=>{
                // console.log(err);
            })
        })
    }
    addVariant(productId,product,variant)
    {
       return this.FireS.collection('products').doc(productId).collection('variety').add(variant.val).then(res=>{
          return this.updateProduct2ndTime(product,productId,res.id,variant.val.barCode);
       })
    }
 
    pushUpload(upload:Upload,para,i,j?)
    {
        if(upload && para == 'products' && i)
        {

        }
        else if(upload && para == 'variety'&& i && j)
        {

        }
    }

    //upload product picture
    pushUpload1(upload,para,productId,i)
    {
        if(upload && productId && (i || i ==0) && para == 'products')
        {
            //name = productId_i+1
            //upload to products/productId/

            //----------------
                let uploadName: string = productId + '_' + (i+1);
                
                    let start: number = upload.file.name.lastIndexOf('.');
                    let len: number = upload.file.name.length;
                    //console.log(upload.file.name.slice(start,len));
                    let ext = upload.file.name.slice(start, len);

                    uploadName = uploadName + ext;
                    //console.log(uploadName);
                
                    const storageRef = firebase.storage().ref(`${productId}`);
 
                
                    const uploadTask = storageRef.child(`${"products"}/${uploadName}`).put(upload.file);
                    return uploadTask
                    .then((snapshot:firebase.storage.UploadTaskSnapshot) => {
                        
                        return snapshot.ref.getDownloadURL().then(res=>{
                          return res;  
                        })

                        //return [snapshot.downloadURL] ;                                         
                        
                    }).catch(err => {
                        //console.log(err);
                        return 0;
                    });

            //------------------

        }
    }
   
    //upload variant picture
    pushUpload2(upload,para,productId,variant,j)
    {
      
        if(upload && productId && para =='variety' && variant && (j ||j==0))
        {
            //name = productId_i+1
            //upload to products/productId/

            //----------------
            if(variant.id)
            {
                let uploadName: string = variant.id + '_' + (j+1);
                
                    let start: number = upload.file.name.lastIndexOf('.');
                    let len: number = upload.file.name.length;
                    //console.log(upload.file.name.slice(start,len));
                    let ext = upload.file.name.slice(start, len);

                    uploadName = uploadName + ext;
                    //console.log(uploadName);
                
                    const storageRef = firebase.storage().ref(`${productId}`);

                
                    const uploadTask = storageRef.child(`${"variety"}/${variant.id}/${uploadName}`).put(upload.file);
                    return uploadTask.then((snapshot: firebase.storage.UploadTaskSnapshot) => {
                         
                        return snapshot.ref.getDownloadURL().then(res=>{
                            return {url:res,id:null};  
                          })                                        
                        
                    }).catch(err => {
                        //console.log(err);
                        return 0;
                    });

            }
            else//for new variant to be added
            {
               return this.FireS.collection('products').doc(productId).collection('variety').add({}).then(newDoc=>{
                   
                    variant.id = newDoc.id;
                    let uploadName: string = variant.id + '_' + (j+1);
                
                    let start: number = upload.file.name.lastIndexOf('.');
                    let len: number = upload.file.name.length;
                    //console.log(upload.file.name.slice(start,len));
                    let ext = upload.file.name.slice(start, len);

                    uploadName = uploadName + ext;
                    //console.log(uploadName);
                
                    const storageRef = firebase.storage().ref(`${productId}`);

                
                    const uploadTask = storageRef.child(`${"variety"}/${variant.id}/${uploadName}`).put(upload.file);
                    return uploadTask.then((snapshot: firebase.storage.UploadTaskSnapshot) => {
                         
                        return snapshot.ref.getDownloadURL().then(res=>{
                            return {url:res,id:newDoc.id};  
                          })                                        
                        
                    }).catch(err => {
                        //console.log(err);
                        return 0;
                    });

                })
               
            }
                
          
        }

    }
    //---------------delete function starts
    deleteOldImages(productId,image,para)
    {
       
        if(image && productId)
        {
            image = image.split('?')[0].split('%2F');
            //console.log('images after splitting in service ready to be deleted');
            //console.log(image);
               // let howLong: number = image.length;
                //image = image[howLong - 1];

                //--------------
            let last = image.length - 1;
 	

                //----------------
            if(para && para == 'variety')
            {
              //  console.log('to delete: '+ productId+'/variety/'+image[last-1]+image[last]);
                const storageRef = firebase.storage().ref(`${productId}`);
                const uploadTask = storageRef.child(`${"variety"}/${image[last-1]}/${image[last]}`);
                if (uploadTask != undefined) {
                    return uploadTask.delete().then((res) => {
                        //console.log('item deleted');
                        return res;
                    }).catch(err => {

                        //console.log('error while deleting file');
                    
                        return 0;
                    });
                }
            }
            else if(para && para == 'products')
            {
                //     console.log('to delete:'+productId+'/products/'+image[last]);  
                    const storageRef = firebase.storage().ref(`${productId}`);
                    const uploadTask = storageRef.child(`${'products'}/${image[last]}`);
                    if (uploadTask != undefined) {
                        return uploadTask.delete().then((res) => {
                            //console.log('item deleted');
                            return res;
                        }).catch(err => {
    
                            //console.log('error while deleting file');
                        
                            return 0;
                        });
                    }
            
            }
            
            else
            {
            //console.log('wrong option');
            }
            
        }
        else
        {
            //console.log('image or productId not found to be deleted');
        }

    }
    //---------------delete function ends here
}
